import React, { useState } from 'react';
import { LatestNews, MostReadNews } from '.';
import { Tabs, Tab } from 'react-bootstrap';

const LatestNewsMostRead = () => {

    const [key, setKey] = useState('LatestNews');


    return (
        <div className='bg-light shadow rounded p-3'>
            <Tabs defaultActiveKey={key} onSelect={(k) => setKey(k)}>
                <Tab eventKey='LatestNews' title='Latest News'>
                   <LatestNews />

                </Tab>
                <Tab eventKey='MostRead' title='Most Read'>
                    <MostReadNews />

                </Tab>
            </Tabs>
           

        </div>
    )
}

export default LatestNewsMostRead;