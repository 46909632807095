import React, { useEffect } from "react";

const AdsComponentAdipolo = ({ slot }) => {


  return (
    <div className="my-1 py-1">
      <div id={`div-gpt-ad-8176806-${slot}`}></div>
    </div>
  );
};

export default AdsComponentAdipolo;
