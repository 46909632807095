import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SiteContext } from "../context";

const BannerTop = ({ num, from, to }) => {
  let navigate = useNavigate();

  const { apiUrl, allBanners } = useContext(SiteContext);

  const [items, setItems] = useState([]);
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    getBanners();
  }, []);

  const getBanners = () => {
    let filtered = allBanners.filter((item) => item.position_ad === "top");
    setItems(filtered);
  };

  return (
    <div style={{ overflow: "hidden" }} className="container bg-light shadow-lg">
      {items.length > 0 && (
        <div className="mb-1 pb-1">
          <div className="text-muted small text-end">ADVERTISEMENT</div>
          <div className="row">
            {items.slice(from, to).map((item) => {
              return (
                <div
                  className={
                    num === 2
                      ? `col-md-6 col-12 px-1 text-center`
                      : num === 3
                      ? `col-md-4 col-12 px-1 text-center`
                      : `col-md-12 justify-content-center items-content-center col-12 px-1 text-center`
                  }
                  key={item.id_ad}
                >
                  {item.url_ad === "" ? (
                    <div>
                      <div className="d-block d-md-none text-center">
                        <img
                          style={{ objectFit: "fill" }}
                          className="px-2"
                          src={`${apiUrl}/${item.file_ad}`}
                          height={100}
                          width={num === 1 ? "100%" : "100%"}
                        />
                      </div>
                      <div className=" d-none d-md-block text-center">
                        <img
                          style={{ objectFit: "fill" }}
                          className="px-2"
                          src={`${apiUrl}/${item.file_ad}`}
                          height={100}
                          width={num === 1 ? "80%" : "100%"}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="text-center">
                      <div className="d-block d-md-none text-center">
                        <a href={`${item.url_ad}`} target="_blank">
                          <img
                            style={{ objectFit: "fill" }}
                            className="px-2"
                            src={`${apiUrl}/${item.file_ad}`}
                            height={100}
                            width={num === 1 ? "100%" : "100%"}
                          />
                        </a>
                      </div>
                      <div className=" d-none d-md-block text-center">
                        <a href={`${item.url_ad}`} target="_blank">
                          <img
                            style={{ objectFit: "fill" }}
                            className="px-2"
                            src={`${apiUrl}/${item.file_ad}`}
                            height={100}
                            width={num === 1 ? "80%" : "100%"}
                          />
                        </a>{" "}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default BannerTop;
