import React, { useEffect } from "react";

const AdsVideoComponentAdipolo = () => {
  useEffect(() => {
    try {
      const script = document.createElement("script");

      script.type = "text/javascript";
      script.src = "https://jscdn.greeter.me/newsexpressngr.comvideo.js";
      script.async = true;

      document.body.appendChild(script);
    } catch (e) {}
  }, []);

  return <div className="aplvideo my-1 py-1"></div>;
};

export default AdsVideoComponentAdipolo;
