import React, { createContext, useState, useEffect, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import moment from "moment";

export const SiteContext = createContext();

export const SiteContextProvider = ({ children }) => {
  const apiUrl = "https://newsexpressngr.com";
  const [userData, setUserData] = useState(null);
  const [siteconfig, setSiteconfig] = useState({});
  const [pages, setPages] = useState([]);
  const [allNews, setAllNews] = useState([]);
  const [breakingNews, setBreakingNews] = useState(null);
  const [allCats, setAllCats] = useState([]);
  const [allSubCats, setAllSubCats] = useState([]);
  const [allSubCatNews, setAllSubCatNews] = useState([]);
  const [allBanners, setAllBanners] = useState([]);
  const [services, setServices] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState("");
  const [fbAccessToken, setFbAccesstoken] = useState(null);
  const [cart, setCart] = useState([]);
  const [orderTotal, setOrderTotal] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [isLoggedOn, setIsLoggedOn] = useState(false);
  const [msg, setMsg] = useState("");
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const [latestArticles, setLatestArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const singleProduct = true;

  useEffect(() => {
    getUserDetails();
    getSiteConfig();
    getServices();
    getAllNews();
    getAllBanners();
  }, []);

  const getAllNews = () => {
    setLoading(true);
    return fetch("https://newsexpressngr.com/api/fetch_all_news.php", {
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          let breaking = res.news.filter(
            (item) =>
              item.newsflag === "breaking" &&
              moment(item.nsubmit).format("MM DD YYYY") ===
                moment().format("MM DD YYYY")
          );

          setAllNews(res.news);
          setAllCats(res.categories);
          setAllSubCats(res.subcategories);
          setToAsync(res.news);
          setBreakingNews(breaking[0]);

          setLoading(false);
        } else if (res.status === 400) {
          getFromAsync();
          console.log(res.msg);
        } else {
          console.log(
            "Error connecting to the server please check you details and try again"
          );
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  // const getSubCategories = () => {
  //   fetch(`${apiUrl}/api/fetch_subcategories.php?action=fetchall`, {
  //     method: "get",
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setAllSubCats(res.subcategories);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const getAllBanners = () => {
    return fetch(
      "https://newsexpressngr.com/api/bannerhorizontal.php?action=fetch_banners",
      { method: "get" }
    )
      .then(processResponse)
      .then((res) => {
        const { statusCode, data } = res;
        if (statusCode === 200) {
          setAllBanners(data.banners);
        } else if (statusCode === 400) {
          if (data.error) {
            console.log(data);
          }
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const setAccessToken = (token) => {
    setFbAccesstoken(token);
  };

  const resetAccessToken = () => {
    setFbAccesstoken(null);
  };

  const getFromAsync = async () => {
    try {
      const storedNews = await localStorage.getItem("storedNews");

      if (storedNews !== null) {
        await setAllNews(JSON.parse(storedNews));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const setToAsync = async (value) => {
    try {
      if (value !== null) {
        const storeNews = JSON.stringify(value);
        await localStorage.setItem("storedNews", storeNews);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // const handleAllSubCatNews = (catnews) => {
  //   setAllSubCatNews(catnews);
  // };

  const getServices = () => {
    fetch(`${apiUrl}/api/pages.php?action=fetchallpages`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          setPages(res.rows);
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const getSiteConfig = () => {
    fetch(`${apiUrl}/api/siteupdate.php?action=fetchsiteconfig`, {
      method: "get",
    })
      .then((res) => {
        return res.json();
      })
      .then(async (response) => {
        setSiteconfig(response.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCurrentPage = (page) => {
    setCurrentPage(page);
  };

  const handleLatestArticles = (latest) => {
    setLatestArticles(latest);
  };

  const slugify = (string) => {
    return string
      .toString()
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w\-]+/g, "")
      .replace(/\-\-+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "");
  };

  const processResponse = (response) => {
    const statusCode = response.status;
    const data = response.json();
    return Promise.all([statusCode, data]).then((res) => ({
      statusCode: res[0],
      data: res[1],
    }));
  };

  const getUserDetails = async () => {
    const fromStorageUser = await localStorage.getItem("user");
    const parsedVUser = JSON.parse(fromStorageUser);
    if (fromStorageUser !== null) {
      await setUserData(parsedVUser);
      await setIsLoggedOn(true);
    }
  };

  const handleSignOut = async () => {
    await setUserData(null);
    await setIsLoggedOn(false);
    await setAccessToken(null);
    await localStorage.removeItem("user");
    await localStorage.removeItem("loggedin", false);
  };

  const handleSignIn = async (data) => {
    const storedUser = JSON.stringify(data);

    await setUserData(data);
    await setIsLoggedOn(true);
    await localStorage.setItem("user", storedUser);
    await localStorage.setItem("loggedin", true);
  };

  const formatNumberK = (num) => {
    if (num > 0) {
      let numprice = num / 1000;

      let num = numprice.toString();
      return `${num}`;
    } else {
      return "0";
    }
  };

  const contextValue = useMemo(() => {
    return {
      apiUrl,
      categories,
      pages,
      allNews,
      breakingNews,
      allBanners,
      allCats,
      allSubCatNews,
      msg,
      isLoggedOn,
      userData,
      userToken,
      siteconfig,
      isMobile,
      currentPage,
      latestArticles,
      allSubCats,
      fbAccessToken,
      formatNumberK,
      slugify,
      handleSignOut,
      handleSignIn,
      setUserData,
      getServices,
      handleCurrentPage,
      handleLatestArticles,
      setAccessToken,
      resetAccessToken,
    };
  }, [
    allNews,
    allBanners,
    isMobile,
    isLoggedOn,
    allCats,
    allSubCats,
    fbAccessToken,
  ]);

  return (
    <SiteContext.Provider value={contextValue}>{children}</SiteContext.Provider>
  );
};
