import React from "react";
import { CbnAd, ShellAd, BannerSide, AdsComponent, SocialMedia } from "./";
import { shell_banner } from "../assets";
import moment from "moment";

const RightColumn = () => {
  return (
    <div className="bg-white my-2 py-2">
      <div className="mb-2 p-2 bg-light">{moment().format("LLLL")}</div>
      
      <ShellAd />
      <CbnAd />
      <AdsComponent dataAdSlot="6291420806" />
      <SocialMedia />
      <BannerSide num={2} />
    </div>
  );
};

export default RightColumn;
