import React, { useState, useEffect, useContext, memo } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../App.css";
import ReactModal from "react-modal";
import { Dropdown } from "react-bootstrap";
import { Cart, SearchBox, Logo, BannerTop, AdsComponent } from "../components";
import { SiteContext } from "../context/index";

const Header = () => {
  const {
    isLoggedOn,
    handleSignOut,
    getOrderItemsTotal,
    breakingNews,
    currentPage,
    handleCurrentPage,
    allCats,
    allNews,
    allSubCats,
    apiUrl,
    siteconfig,
    isMobile,
    singleProduct,
  } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem("user"));

  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState("");
  const [mType, setMType] = useState("");
  const [showBreaking, setShowBreaking] = useState(true);
  const [userClosed, setUserClosed] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    if (currentPage !== "") setPage(currentPage);
  }, [currentPage]);

  const doSignOut = () => {
    navigate("/");
    handleSignOut();
  };

  const switchModal = (item) => {
    if (item === "menu") {
      setMType("menu");
    } else if (item === "search") {
      setMType("search");
    } else if (item === "user") {
      setMType("user");
    }
    setShowModal(true);
  };

  const handleUserAction = () => {
    setShowBreaking(false);
    setUserClosed(true);
  };

  const HeaderMenu = () => {
    return (
      <div className="bg-dark">
        <div>
          <Link
            className="p-2 bg-dark btn w-100 rounded-0 text-start border-bottom text-white"
            to="/"
          >
            Home
          </Link>
        </div>
        {allSubCats.length > 0 &&
          allSubCats
            .filter(
              (item) =>
                item.nsubcat !== "Advertorials" &&
                item.nsubcat !== "African Press Organisation" &&
                item.nsubcat !== "Art & Literature" &&
                item.nsubcat !== "B-I-Z-A-R-R-E" &&
                item.nsubcat !== "Business Verdict" &&
                item.nsubcat !== "Complaints & Requests" &&
                item.nsubcat !== "Enterprise & Opportunities" &&
                item.nsubcat !== "News and Events" &&
                item.nsubcat !== "Live Commentary" &&
                item.nsubcat !== "Pidgin" &&
                item.nsubcat !== "Stock Watch" &&
                item.nsubcat !== "Interview" &&
                item.nsubcat !== "Love Matters" &&
                item.nsubcat !== "Religion" &&
                item.nsubcat !== "Tombo-Talk" &&
                item.nsubcat !== "Global Business Monitor" &&
                item.nsubcat !== "Features" &&
                item.nsubcat !== "Columnists"
            )
            .map((item, i) => {
              return (
                <Link
                  key={i}
                  className="p-2 bg-dark btn w-100 rounded-0 text-start border-bottom text-white"
                  to={`/sub-category/${item.nsubcatid}/${item.nsubcat
                    .toLowerCase()
                    .split(" ")
                    .join("-")}`}
                  onClick={() => showModal(false)}
                >
                  {item.nsubcat}
                </Link>
              );
            })}
        <div>
          <Link
            className="p-2 bg-dark btn w-100 rounded-0 text-start border-bottom text-white"
            to="/photos"
          >
            Photo Gallery
          </Link>
        </div>
        <div>
          <Link
            className="p-2 bg-dark btn w-100 rounded-0 text-start border-bottom text-white"
            to="/videos"
          >
            NewsExpress TV
          </Link>
        </div>
      </div>
    );
  };

  const UserMenu = () => {
    return (
      <div>
        {userData ? (
          <h4 className="py-1 border-bottom">Hello {userData.name}</h4>
        ) : (
          <h4 className="py-1 border-bottom">Hello Guest</h4>
        )}
        <div className="py-1 border-bottom">
          <Link className="my-1 text-decoration-none" to="/user">
            My Account
          </Link>
        </div>
      </div>
    );
  };

  return (
    <div>
      <ReactModal
        isOpen={showModal}
        ariaHideApp={false}
        style={{
          overlay: {
            flex: 1,
            justifyContent: "center",
            alignContent: "center",
            position: "fixed",
            top: isMobile ? 0 : "25%",
            bottom: isMobile ? 0 : "25%",
            left: isMobile ? 0 : "20%",
            right: isMobile ? 0 : "20%",
            width: isMobile ? "100%" : "60%",
            height: isMobile ? "100%" : "50&",
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          content: {
            position: "absolute",
            top: "10px",
            left: "10px",
            right: "10px",
            bottom: "10px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "10px",
          },
        }}
      >
        <div>
          <div className="d-flex justify-content-end">
            <span
              className="btn btn-dark mb-2 py-1"
              onClick={() => setShowModal(false)}
            >
              <i className="bi bi-x h3 text-white"></i>
            </span>
          </div>
          {mType === "menu" && <HeaderMenu />}
          {mType === "search" && <SearchBox />}{" "}
          {mType === "user" && <UserMenu />}
        </div>
      </ReactModal>

      <div
        style={{
          backgroundColor: "#ffffff",
        }}
      >
        <BannerTop num={1} from={0} to={1} />

        <div className="container">
          <div className="row py-3">
            <div className="col-lg-5 col-4 d-flex justify-content-start align-items-center">
              <Link to="/" className="text-decoration-none py-1">
                <Logo />
              </Link>
            </div>
            <div className="col-lg-7 col-8 d-flex justify-content-end align-items-center">
              <div style={{ width: "100%" }} className="d-none d-lg-block">
                <BannerTop num={1} from={1} to={2} />
              </div>
              <div className="d-block d-lg-none">
                <div className="d-flex justify-content-center align-items-center">
                  <button
                    className="px-2 text-decoration-none btn btn-transparent text-dark"
                    onClick={() => switchModal("search")}
                  >
                    <i className="bi bi-search h2"></i>
                  </button>

                  {isLoggedOn ? (
                    <div className="px-1 py-0 my-0">
                      <Dropdown>
                        <Dropdown.Toggle variant="dark" id="dropdown-basic">
                          <span className="h-[25px], w-[25px]">
                            <img
                              src={
                                userData.photo.includes("images/profile")
                                  ? `${apiUrl}/${userData.photo}`
                                  : `${userData.photo}`
                              }
                              height={20}
                              width={20}
                              className="inline-block rounded-circle"
                              alt={userData.name}
                            />
                          </span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <h6 className="border-bottom">
                              Hello {userData.name}
                            </h6>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => navigate("/user")}>
                            Account
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => doSignOut()}>
                            <i className="bi bi-box-arrow-right h6"></i> Logout
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  ) : (
                    <div className="px-1 py-0 my-0">
                      <Dropdown>
                        <Dropdown.Toggle variant="dark" id="dropdown-basic">
                          <i className="bi bi-person h4"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <h6 className="border-bottom">Hello Guest</h6>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => navigate("/login")}>
                            Login
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => navigate("/signup")}>
                            Signup
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  )}
                  <span className="px-2">
                    <button
                      className="px-2 text-decoration-none btn btn-dark text-white"
                      onClick={() => switchModal("menu")}
                    >
                      <i className="bi bi-list text-white h4"></i>
                    </button>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 d-block d-lg-none">
              <BannerTop num={1} from={1} to={2} />
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "#f00" }}>
        <div className="container d-lg-block d-none">
          <div className="row">
            <div className="col-lg-10 d-flex justify-content-start">
              <Link className="btn rounded-0 text-start text-white" to="/">
                <i className="bi bi-house"></i>
              </Link>
              {allSubCats.length > 0 &&
                allSubCats
                  .filter(
                    (item) =>
                      item.nsubcat !== "Advertorials" &&
                      item.nsubcat !== "African Press Organisation" &&
                      item.nsubcat !== "Art & Literature" &&
                      item.nsubcat !== "B-I-Z-A-R-R-E" &&
                      item.nsubcat !== "Business Verdict" &&
                      item.nsubcat !== "Complaints & Requests" &&
                      item.nsubcat !== "Enterprise & Opportunities" &&
                      item.nsubcat !== "News and Events" &&
                      item.nsubcat !== "Live Commentary" &&
                      item.nsubcat !== "Pidgin" &&
                      item.nsubcat !== "ICT Monitor" &&
                      item.nsubcat !== "Maggie's Blog" &&
                      item.nsubcat !== "Stock Watch" &&
                      item.nsubcat !== "Interview" &&
                      item.nsubcat !== "Love Matters" &&
                      item.nsubcat !== "Religion" &&
                      item.nsubcat !== "Tombo-Talk" &&
                      item.nsubcat !== "Global Business Monitor" &&
                      item.nsubcat !== "Features" &&
                      item.nsubcat !== "Columnists"
                  )
                  .map((item, i) => {
                    return (
                      <Link
                        key={i}
                        style={{ fontSize: 12 }}
                        className="px-1 btn rounded-0 text-start text-white"
                        to={`/sub-category/${item.nsubcatid}/${item.nsubcat
                          .toLowerCase()
                          .split(" ")
                          .join("-")}`}
                      >
                        {item.nsubcat === "Business & Economy"
                          ? "BUSINESS"
                          : item.nsubcat.toUpperCase()}
                      </Link>
                    );
                  })}
              <Link
                style={{ fontSize: 12 }}
                className="px-1 btn rounded-0 text-start px-0 text-white"
                to={`/sub-category/61/ict-monitor`}
              >
                ICT MONITOR
              </Link>
              <Link
                style={{ fontSize: 12 }}
                className="px-1 btn rounded-0 text-start px-0 text-white"
                to={`/sub-category/60/maggies-blog`}
              >
                MAGGIE'S BLOG
              </Link>
              <Link
                style={{ fontSize: 12 }}
                className="btn rounded-0 text-start px-0 text-white"
                to={`/category/12/news-extra`}
              >
                News Extra
              </Link>
              <Link
                style={{ backgroundColor: "#000000" }}
                className="px-1 btn rounded-0 text-start px-0 text-white"
                to="/videos"
              >
                NewsExpress TV
              </Link>

              <div className="inline-block">
                <Dropdown>
                  <Dropdown.Toggle
                    className="btn border-0"
                    style={{ color: "black" }}
                    variant="transparent"
                    id="none"
                  >
                    <span className="text-white small py-0">
                      <i className="bi bi-list h4"></i>
                    </span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ backgroundColor: "#000000" }}>
                    {allSubCats
                      .filter(
                        (item) =>
                          item.nsubcat !== "Politics" &&
                          item.nsubcat !== "News" &&
                          item.nsubcat !== "Sports" &&
                          item.nsubcat !== "Entertainment" &&
                          item.nsubcat !== "Business & Economy" &&
                          item.nsubcat !== "Opinion" &&
                          item.nsubcat !== "International" &&
                          item.nsubcat !== "Maggie's Blog" &&
                          item.nsubcat !== "ICT Monitor" &&
                          item.nsubcat !== "Opinion"
                      )
                      .map((item, i) => {
                        return (
                          <Dropdown.Item style={{ backgroundColor: "#000000" }}>
                            <Link
                              key={i}
                              style={{ backgroundColor: "#000000" }}
                              className="px-1 py-0 btn rounded-0 text-start text-white"
                              to={`/sub-category/${
                                item.nsubcatid
                              }/${item.nsubcat
                                .toLowerCase()
                                .split(" ")
                                .join("-")}`}
                            >
                              {item.nsubcat}
                            </Link>
                          </Dropdown.Item>
                        );
                      })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <div className="col-lg-2 col-2 d-lg-block d-none d-flex justify-content-end align-items-center">
              <div className="d-flex justify-content-end">
                <button
                  className="px-2 text-decoration-none btn btn-transparent btn-sm text-dark"
                  onClick={() => switchModal("search")}
                >
                  <i className="bi bi-search h3"></i>
                </button>

                {isLoggedOn ? (
                  <div className="px-1 py-0 my-0">
                    <Dropdown>
                      <Dropdown.Toggle variant="dark" id="dropdown-basic">
                        <span className="h-[25px], w-[25px]">
                          <img
                            src={
                              userData.photo.includes("images/profile")
                                ? `${apiUrl}/${userData.photo}`
                                : `${userData.photo}`
                            }
                            height={20}
                            width={20}
                            className="inline-block rounded-circle"
                            alt={userData.name}
                          />{" "}
                        </span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <h6 className="border-bottom">
                            Hello {userData.name}
                          </h6>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => navigate("/user")}>
                          Account
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => doSignOut()}>
                          <i className="bi bi-box-arrow-right h6"></i> Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                ) : (
                  <div className="px-1 py-0 my-0">
                    <Dropdown>
                      <Dropdown.Toggle variant="dark" id="dropdown-basic">
                        <i className="bi bi-person h4"></i>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <h6 className="border-bottom">Hello Guest</h6>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => navigate("/login")}>
                          Login
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => navigate("/signup")}>
                          Signup
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: "#ffffff" }}>
          <BannerTop num={3} from={2} to={5} />
        </div>
      </div>

      {allNews.length > 0 && !userClosed && showBreaking && (
        <div style={{ backgroundColor: "green" }}>
          <div className="container py-1">
            <div className="d-flex justify-content-between">
              <span className="p-1 float-left bg-dark text-white h6 small">
                Latest News
              </span>
              <button
                className="h5 px-1 rounded-radius text-white float-right bg-transparent border-0"
                onClick={() => handleUserAction()}
              >
                X
              </button>
            </div>
            <div id="scroll-container">
              <marquee width="100%" direction="left" height="30px">
                {allNews.slice(0, 5).map((item, i) => {
                  return (
                    <Link
                      key={i}
                      className="mx-1 px-1 text-decoration-none text-white"
                      to={`/news/${item.newsid}/${item.ntitle_slug}`}
                    >
                      {item.ntitle}
                    </Link>
                  );
                })}
              </marquee>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(Header);
