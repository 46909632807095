import React, { useState, useEffect, useContext } from "react";
import { ImageDisplay } from "./";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SiteContext } from "../context";
import { formatViews } from "../utils";

const MostReadNews = () => {
  const { apiUrl, allNews, isMobile } = useContext(SiteContext);

  const [mostRead, setMostRead] = useState([])

  useEffect(() => {
    getMostRead()
  },[])

  const getMostRead = () => {
    return fetch("https://newsexpressngr.com/api/fetch_most_read.php", {
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {

          setMostRead(res.news);
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };


  return (
    <div className="container">
      {mostRead.length > 0 && (
        <div>
         {mostRead.map((item, i) => {
            return (
              <div
                style={{ backgroundColor: "#ffffff" }}
                key={i}
                className="my-3 py-2 border-bottom"
              >
                <Link
                  className="text-decoration-none text-dark"
                  to={`/news/${item.newsid}/${item.ntitle_slug}`}
                >
                  <div className="row">
                    <div className="col-3">
                      {item.nphoto !== undefined &&
                      item.nphoto !== "" &&
                      item.nphoto !== null ? (
                        <ImageDisplay
                          url={`${item.nphoto}`}
                          title={item.ntitle}
                          height={isMobile ? 60 : 70}
                        />
                      ) : (
                        <div
                          style={{
                            width: "100%",
                            height: isMobile ? 60 : 70,
                            backgroundColor: "#333",
                          }}
                        ></div>
                      )}
                    </div>
                    <div className="col-9 mx-0 px-0">
                      <p className="h6">{item.ntitle} (<span className="text-sm text-muted font-italic">{formatViews(item.nhit)}</span>)</p>
                      
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MostReadNews;
