import React, { useContext } from "react";
import Youtube from "react-youtube";
import { SiteContext } from "../context";

const VideoDisplay = ({ url, title, showTitle, opts }) => {
  const { apiUrl } = useContext(SiteContext);

  const yId = (urllink) => {
    let vid = urllink.substring(31, 42);
    return vid;
  };

  return (
    <div>
      <Youtube videoId={yId(url)} title={title} opts={opts} />
      {showTitle && <div className="text-white p-2 bg-dark">{title}</div>}
    </div>
  );
};

export default VideoDisplay;
