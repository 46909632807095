const logo = require("../images/newsexpress_logo.png");
const showbiz_xtra = require("../images/showbiz-xtra.jpg");
const news_xtra = require("../images/news_xtra.jpg");
const maggies_blog_logo = require("../images/maggies_blog_logo.jpeg");
const cbn_ad = require("../images/0E028228-C8BA-4F3F-9946-337F39EED370.gif");
const gocop_logo = require("../images/GOCOP-LOGO140x120.png");
const shell_banner = require("../images/shell-briefing-note-2023_88_gif33_redo-33-01.gif");
const shell_ad = require("../images/shell.png");

export {
  logo,
  showbiz_xtra,
  news_xtra,
  maggies_blog_logo,
  cbn_ad,
  gocop_logo,
  shell_banner,
  shell_ad
};
