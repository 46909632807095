import React, { useState, useEffect, useContext } from "react";
import { ImageDisplay } from "./";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SiteContext } from "../context";

const LatestNews = () => {
  const { apiUrl, allNews, isMobile } = useContext(SiteContext);

  return (
    <div className="container">
      {allNews.length > 0 && (
        <div>
          {allNews.slice(0, 20).map((item, i) => {
            return (
              <div
                style={{ backgroundColor: "#ffffff" }}
                key={i}
                className="my-3 py-2 border-bottom"
              >
                <Link
                  className="text-decoration-none text-dark"
                  to={`/news/${item.newsid}/${item.ntitle_slug}`}
                >
                  <div className="row">
                    <div className="col-3">
                      {item.nphoto !== undefined &&
                      item.nphoto !== "" &&
                      item.nphoto !== null ? (
                        <ImageDisplay
                          url={`${item.nphoto}`}
                          title={item.ntitle}
                          height={isMobile ? 60 : 70}
                        />
                      ) : (
                        <div
                          style={{
                            width: "100%",
                            height: isMobile ? 60 : 70,
                            backgroundColor: "#333",
                          }}
                        ></div>
                      )}
                    </div>
                    <div className="col-9 mx-0 px-0">
                      <p className="h6">{item.ntitle}</p>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default LatestNews;
