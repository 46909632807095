import React, { useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { SiteContext } from "../context";

const CarouselDisplay = ({ data, type }) => {
  const { userData, apiUrl, handleCurrentPage, isMobile } =
    useContext(SiteContext);

  return (
    <div>
      {data.length > 0 && type === "news" ? (
        <Carousel style={{ backgroundColor: "black" }}>
          {data.map((item) => {
            return (
              <Carousel.Item interval={2000} key={item.newsid}>
                <Link to={`/news/${item.newsid}/${item.ntitle_slug}`}>
                  {" "}
                  <img
                    style={{ objectFit: "fill" }}
                    className="d-block w-100"
                    src={`${apiUrl}/${item.nphoto}`}
                    height={isMobile ? 300 : 400}
                    width="100%"
                    alt={item.ntitle}
                  />
                  <div
                    className="mt-0 p-4 rounded"
                    style={{
                      padding: "5% !important",
                      color: "#fff",
                      position: "absolute",
                      backgroundColor: "#333",
                      opacity: 0.8,
                      left: isMobile ? 0 : "5%",
                      right: isMobile ? 0 : "5%",
                      bottom: 0,
                      width: isMobile ? "100%" : "90%",
                    }}
                  >
                    <p className={`text-white small`}>{item.ntitle}</p>
                  </div>
                  <Carousel.Caption></Carousel.Caption>
                </Link>
              </Carousel.Item>
            );
          })}
        </Carousel>
      ) : (
        <Carousel style={{ backgroundColor: "black" }}>
          {data.map((item) => {
            return (
              <Carousel.Item interval={2000} key={item.galleryid}>
                <img
                  style={{ objectFit: "fill" }}
                  className="d-block w-100"
                  src={`${apiUrl}/images/gallery/${item.gphoto}`}
                  height={isMobile ? 300 : 400}
                  width="100%"
                  alt={item.gtype}
                />
                <div
                  className="mt-0 p-4 rounded"
                  style={{
                    padding: "5% !important",
                    color: "#fff",
                    position: "absolute",
                    backgroundColor: "#333",
                    opacity: 0.8,
                    left: isMobile ? 0 : "5%",
                    right: isMobile ? 0 : "5%",
                    bottom: 0,
                    width: isMobile ? "100%" : "90%",
                  }}
                >
                  <p className={`text-white small`}>{item.gdesc}</p>
                </div>
                <Carousel.Caption></Carousel.Caption>
              </Carousel.Item>
            );
          })}
        </Carousel>
      )}
    </div>
  );
};

export default CarouselDisplay;
