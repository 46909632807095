import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { cbn_ad } from "../assets";
import { SiteContext } from "../context";

const CbnAd = () => {
  return (
    <div className="rounded shadow bg-white my-2 py-2">
      <div className="text-muted small text-end">ADVERTISEMENT</div>

      <div className="px-1 my-2 text-center">
        <img
          style={{ objectFit: "cover" }}
          className="img-fluid"
          src={cbn_ad}
          height={280}
          width={`100%`}
        />
      </div>
    </div>
  );
};

export default CbnAd;
