import React, { useContext } from "react";
import { SiteContext } from "../context";

const SocialMedia = () => {
  const { siteconfig } = useContext(SiteContext);

  return (
    <div className="my-1 py-2">
      {(siteconfig.slfacebook !== "" ||
        siteconfig.sltwitter !== "" ||
        siteconfig.slinstagram !== "" ||
        siteconfig.slyoutube !== "") && (
        <p className="h5 mt-2 my-1">Follow us on</p>
      )}
      {siteconfig.slfacebook != "" && (
        <span className="p-1 my-1">
          <a
            className="py-1 rounded-circle h4"
            href={siteconfig.slfacebook}
            target="_blank"
          >
            <i className="bi bi-facebook"></i>
          </a>
        </span>
      )}
      {siteconfig.sltwitter != "" && (
        <span className="p-1 my-1">
          <a
            className="py-1 rounded-circle h4"
            href={siteconfig.sltwitter}
            target="_blank"
          >
            <i className="bi bi-twitter"></i>
          </a>
        </span>
      )}
      {siteconfig.slinstagram != "" && (
        <span className="p-1 my-1">
          <a
            className="py-1 rounded-circle h4"
            href={siteconfig.slinstagram}
            target="_blank"
          >
            <i className="bi bi-instagram"></i>
          </a>
        </span>
      )}
      {siteconfig.slyoutube != "" && (
        <span className="p-1 my-1">
          <a
            className="py-1 rounded-circle h4"
            href={siteconfig.slyoutube}
            target="_blank"
          >
            <i className="bi bi-youtube"></i>
          </a>
        </span>
      )}
    </div>
  );
};

export default SocialMedia;
