import React, { lazy, Suspense, useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import { Home, NothingMatch, Activation } from "../screens";
import ErrorBoundary from "../ErrorBoundary";

import { SiteContext } from "../context";

const Videos = lazy(() => import("../screens/Videos"));
const Video = lazy(() => import("../screens/Video"));
const Login = lazy(() => import("../screens/Login"));
const Signup = lazy(() => import("../screens/Signup"));
const Profile = lazy(() => import("../screens/Profile"));

const Register = lazy(() => import("../screens/Register"));
const Account = lazy(() => import("../screens/Account"));
const Users = lazy(() => import("../screens/Users"));
const UserDashboard = lazy(() => import("../screens/UserDashboard"));
const UserProfileDisplay = lazy(() => import("../screens/UserProfileDisplay"));
const UpdatePassword = lazy(() => import("../screens/UpdatePassword"));
const Contact = lazy(() => import("../screens/Contact"));
const About = lazy(() => import("../screens/About"));
const Abouts = lazy(() => import("../screens/Abouts"));
const SitePages = lazy(() => import("../screens/SitePages"));
const SitePageAdd = lazy(() => import("../screens/SitePageAdd"));
const SitePageUpdate = lazy(() => import("../screens/SitePageUpdate"));
const PhotoGallery = lazy(() => import("../screens/PhotoGallery"));

const SiteConfigUpdate = lazy(() => import("../screens/SiteConfigUpdate"));
const SiteConfig = lazy(() => import("../screens/SiteConfig"));
const PageDetail = lazy(() => import("../screens/PageDetail"));
const AdminNewsletters = lazy(() => import("../screens/AdminNewsletters"));
const NewsletterAdd = lazy(() => import("../screens/NewsletterAdd"));
const NewsletterUpdate = lazy(() => import("../screens/NewsletterUpdate"));
const AdminPhotoGallery = lazy(() => import("../screens/AdminPhotoGallery"));
const PhotoGalleryAdd = lazy(() => import("../screens/PhotoGalleryAdd"));
const PhotoGalleryUpdate = lazy(() => import("../screens/PhotoGalleryUpdate"));
const AdminPhotoGalleryCat = lazy(() =>
  import("../screens/AdminPhotoGalleryCat")
);
const PhotoGalleryCatAdd = lazy(() => import("../screens/PhotoGalleryCatAdd"));
const PhotoGalleryCatUpdate = lazy(() =>
  import("../screens/PhotoGalleryCatUpdate")
);
const NewsCategory = lazy(() => import("../screens/NewsCategory"));
const NewsSubCategory = lazy(() => import("../screens/NewsSubCategory"));
const NewsDetail = lazy(() => import("../screens/NewsDetail"));
const AdminBannerAds = lazy(() => import("../screens/AdminBannerAds"));
const AdminBannerAdsUpdate = lazy(() =>
  import("../screens/AdminBannerAdsUpdate")
);
const AdminBannerAdsAdd = lazy(() => import("../screens/AdminBannerAdsAdd"));
const AdminComments = lazy(() => import("../screens/AdminComments"));
const AdminNews = lazy(() => import("../screens/AdminNews"));
const AdminNewsAdd = lazy(() => import("../screens/AdminNewsAdd"));
const AdminNewsUpdate = lazy(() => import("../screens/AdminNewsUpdate"));
const AdminNewsCategories = lazy(() =>
  import("../screens/AdminNewsCategories")
);
const AdminNewsSubCategories = lazy(() =>
  import("../screens/AdminNewsSubCategories")
);
const AdminVideoGallery = lazy(() => import("../screens/AdminVideoGallery"));
const AdminVideoGalleryCat = lazy(() =>
  import("../screens/AdminVideoGalleryCat")
);
const VideoGalleryAdd = lazy(() => import("../screens/VideoGalleryAdd"));
const VideoGalleryCatAdd = lazy(() => import("../screens/VideoGalleryCatAdd"));
const VideoGalleryCatUpdate = lazy(() =>
  import("../screens/VideoGalleryCatUpdate")
);
const VideoGalleryUpdate = lazy(() => import("../screens/VideoGalleryUpdate"));

const SiteRoutes = () => {
  const { isLoggedOn } = useContext(SiteContext);

  const loggedin = localStorage.getItem("loggedin");

  const userloggedOn = loggedin || isLoggedOn;

  const RequireAuth = ({ children }) => {
    if (!userloggedOn) {
      return <Navigate to="/login" />;
    }

    return children;
  };

  const AuthNav = () => {
    return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/register" element={<Register />} />
        <Route path="/activation/:rkey/:email" element={<Activation />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/company" element={<Abouts />} />
        <Route path="/company/:title" element={<About />} />
        <Route path="/category/:id/:title" element={<NewsCategory />} />
        <Route
          path="/sub-category/:sid/:section"
          element={<NewsSubCategory />}
        />
        <Route path="/news/:id/:title" element={<NewsDetail />} />
        <Route
          path="/adminnews"
          element={
            <RequireAuth>
              <AdminNews />
            </RequireAuth>
          }
        />
        <Route
          path="/addnews"
          element={
            <RequireAuth>
              <AdminNewsAdd />
            </RequireAuth>
          }
        />
        <Route
          path="/updatenews"
          element={
            <RequireAuth>
              <AdminNewsUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/adminnewscategories"
          element={
            <RequireAuth>
              <AdminNewsCategories />
            </RequireAuth>
          }
        />
        <Route
          path="/adminnewssubcategories"
          element={
            <RequireAuth>
              <AdminNewsSubCategories />
            </RequireAuth>
          }
        />
        <Route
          path="/admincomments"
          element={
            <RequireAuth>
              <AdminComments />
            </RequireAuth>
          }
        />
        <Route
          path="/adminbannerads"
          element={
            <RequireAuth>
              <AdminBannerAds />
            </RequireAuth>
          }
        />
        <Route
          path="/banneradadd"
          element={
            <RequireAuth>
              <AdminBannerAdsAdd />
            </RequireAuth>
          }
        />
        <Route
          path="/banneradupdate"
          element={
            <RequireAuth>
              <AdminBannerAdsUpdate />
            </RequireAuth>
          }
        />

        <Route
          path="/users"
          element={
            <RequireAuth>
              <Users />
            </RequireAuth>
          }
        />
        <Route path="/photos" element={<PhotoGallery />} />

        <Route
          path="/addpage"
          element={
            <RequireAuth>
              <SitePageAdd />
            </RequireAuth>
          }
        />
        <Route
          path="/updatepage"
          element={
            <RequireAuth>
              <SitePageUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/adminpages"
          element={
            <RequireAuth>
              <SitePages />
            </RequireAuth>
          }
        />
        <Route
          path="/addphotogallery"
          element={
            <RequireAuth>
              <PhotoGalleryAdd />
            </RequireAuth>
          }
        />
        <Route
          path="/updatephotogallery"
          element={
            <RequireAuth>
              <PhotoGalleryUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/adminphotogallery"
          element={
            <RequireAuth>
              <AdminPhotoGallery />
            </RequireAuth>
          }
        />
        <Route
          path="/addphotogallerycat"
          element={
            <RequireAuth>
              <PhotoGalleryCatAdd />
            </RequireAuth>
          }
        />
        <Route
          path="/updatephotogallerycat"
          element={
            <RequireAuth>
              <PhotoGalleryCatUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/adminphotogallerycat"
          element={
            <RequireAuth>
              <AdminPhotoGalleryCat />
            </RequireAuth>
          }
        />
        <Route
          path="/addvideogallery"
          element={
            <RequireAuth>
              <VideoGalleryAdd />
            </RequireAuth>
          }
        />
        <Route
          path="/updatevideogallery"
          element={
            <RequireAuth>
              <VideoGalleryUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/adminvideogallery"
          element={
            <RequireAuth>
              <AdminVideoGallery />
            </RequireAuth>
          }
        />
        <Route
          path="/addvideogallerycat"
          element={
            <RequireAuth>
              <VideoGalleryCatAdd />
            </RequireAuth>
          }
        />
        <Route
          path="/updatevideogallerycat"
          element={
            <RequireAuth>
              <VideoGalleryCatUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/adminvideogallerycat"
          element={
            <RequireAuth>
              <AdminVideoGalleryCat />
            </RequireAuth>
          }
        />
        <Route
          path="/addnewsletter"
          element={
            <RequireAuth>
              <NewsletterAdd />
            </RequireAuth>
          }
        />
        <Route
          path="/updatenewsletter"
          element={
            <RequireAuth>
              <NewsletterUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/adminnewsletters"
          element={
            <RequireAuth>
              <AdminNewsletters />
            </RequireAuth>
          }
        />
        <Route
          path="/adminsiteupdate"
          element={
            <RequireAuth>
              <SiteConfigUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/adminsiteconfig"
          element={
            <RequireAuth>
              <SiteConfig />
            </RequireAuth>
          }
        />
        <Route path="/page/:id" element={<PageDetail />} />
        <Route path="/videos" element={<Videos />} />
        <Route path="/video/:id/:title" element={<Video />} />
        <Route
          path="/profile"
          element={
            <RequireAuth>
              <Profile />
            </RequireAuth>
          }
        />
        <Route
          path="/changepassword"
          element={
            <RequireAuth>
              <UpdatePassword />
            </RequireAuth>
          }
        />
        <Route
          path="/userprofile"
          element={
            <RequireAuth>
              <UserProfileDisplay />
            </RequireAuth>
          }
        />
        <Route
          path="/account"
          element={
            <RequireAuth>
              <Account />
            </RequireAuth>
          }
        />
        <Route
          path="/user"
          element={
            <RequireAuth>
              <UserDashboard />
            </RequireAuth>
          }
        />
        <Route path="*" element={<NothingMatch />} />
      </Routes>
    );
  };

  return (
    <Router>
      <ErrorBoundary>
        <Suspense
          fallback={
            <div className="d-flex justify-content-center align-content-center my-5">
              <div class="spinner-border text-secondary" role="status" />
            </div>
          }
        >
          <AuthNav />
        </Suspense>
      </ErrorBoundary>
    </Router>
  );
};

export default SiteRoutes;
