import React, { useState, useEffect, useContext } from "react";
import { Header, Footer, ProjectGallery } from "./";
import { Link, useNavigate } from "react-router-dom";
import { SiteContext } from "../context";
import {
  ImageDisplay,
  VideoDisplay,
  CategoryNewsDisplay,
  BannerTop,
  BannerSide,
  CarouselDisplay,
  LatestNews,
  RightColumn,
} from "../components";
import moment from "moment";
import {
  aboutImage,
  localTerrain,
  maggies_blog_logo,
  showbiz_xtra,
  news_xtra
} from "../assets";
import Youtube from "react-youtube";
import { removeTags, getHeading } from "../utils";
import Carousel from "react-bootstrap/Carousel";
import Accordion from "react-bootstrap/Accordion";
import Seo from "./Seo";
import LatestNewsMostRead from "../components/LatestNewsMostRead";

const Home = () => {
  const {
    userData,
    apiUrl,
    loading,
    siteconfig,
    allNews,
    allCats,
    allSubCats,
    isLoggedOn,
    categories,
    pages,
    handleCurrentPage,
    isMobile,
  } = useContext(SiteContext);

  const navigate = useNavigate();

  const [photos, setPhotos] = useState([]);
  const [maggiesBlog, setMaggiesBlog] = useState([]);
  const [showbiz, setShowbiz] = useState([]);
  const [allVideos, setAllVideos] = useState([]);
  const [videoLoading, setVideoLoading] = useState(false);

  useEffect(() => {
    handleCurrentPage("home");
    getPhotos();
    getAllVideos();
    getMaggiesShowbiz();
  }, []);

  const getPhotos = () => {
    return fetch(`${apiUrl}/api/photogallery.php?action=fetch_home`, {
      method: "get",
    })
      .then(processResponse)
      .then(async (res) => {
        const { statusCode, data } = res;
        if (statusCode === 200) {
          setPhotos(data.photos);
        } else if (statusCode === 400) {
          if (data.error) {
            console.log(data.error);
          }
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const getAllVideos = () => {
    setVideoLoading(true);
    return fetch(`${apiUrl}/api/allvideos.php?action=fetch`, {
      method: "get",
    })
      .then(processResponse)
      .then(async (res) => {
        const { statusCode, data } = res;
        if (statusCode === 200) {
          setAllVideos(data.videos);
          setVideoLoading(false);
        } else if (statusCode === 400) {
          if (data.error) {
            console.log(data.error);
          }
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const getMaggiesShowbiz = () => {
    fetch("https://newsexpressngr.com/api/homedata.php?action=fetch", {
      method: "get",
    })
      .then(processResponse)
      .then(async (res) => {
        const { statusCode, data } = res;
        if (statusCode === 200) {
          setMaggiesBlog(data.maggiesblog);
          setShowbiz(data.showbiz);
        } else if (statusCode === 400) {
          if (data.error) {
            console.log(data.error);
          }
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const yId = (urllink) => {
    let vid = urllink.substring(31, 42);
    return vid;
  };

  const processResponse = (response) => {
    const statusCode = response.status;
    const data = response.json();
    return Promise.all([statusCode, data]).then((res) => ({
      statusCode: res[0],
      data: res[1],
    }));
  };

  const processText = (text) => {
    let thetext = removeTags(text);

    thetext = getHeading(thetext, 25);

    return thetext;
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-content-center my-5">
        <div class="spinner-border text-secondary" role="status" />
      </div>
    );
  }

  return (
    <div>
      <Seo
        title={`Home | ${siteconfig.shopname}`}
        description={siteconfig.shopdescription}
        url={`${apiUrl}`}
        img={`${apiUrl}/${siteconfig.shoplogo}`}
      />
      <Header />

      <div className="container py-3 my-1">
        <div className="row">
          <div className="col-md-4 order-2 order-md-1">
            <div style={{ backgroundColor: "#ffffff" }} className="my-2 py-3">
              <div className="container">
                {/* {videoLoading && (
                  <div className="d-flex justify-content-center align-content-center my-5">
                    <div class="spinner-border text-secondary" role="status" />
                  </div>
                )} */}

                {allVideos.length > 0 && (
                  <div>
                    <div
                      style={{ backgroundColor: "#ff0000" }}
                      className="p-2 my-0 text-white text-center h3"
                    >
                      <span style={{ color: "#000000" }}>NEWS</span>EXPRESS
                      NIGERIA TV
                    </div>
                    <div className="row">
                      {allVideos.slice(0, 3).map((item, i) => {
                        const opts = {
                          height: "240",
                          width: "100%",
                        };

                        return (
                          <div key={i} className="p-3">
                            <Link
                              className="text-decoration-none text-dark"
                              to={`/video/${item.videntryid}/${item.vidtitle
                                .toLowerCase()
                                .split(" ")
                                .join("-")}/`}
                            >
                              <VideoDisplay
                                url={`${item.vidurl}`}
                                title={item.vidtitle}
                                showTitle
                                opts={opts}
                              />
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>

              {photos.length > 0 && (
                <div className="my-2 py-2">
                  <p className="h3">Photo Gallery</p>
                  <CarouselDisplay data={photos} type="gallery" />
                </div>
              )}

              <div>
                {maggiesBlog.length > 0 && (
                  <div style={{ backgroundColor: "#F37652", color: "#ffffff" }}>
                    <img
                      src={maggies_blog_logo}
                      width={"100%"}
                      height={"100%"}
                      className="rounded mb-2"
                    />
                    <div className="p-1">
                      <div className="row">
                        {maggiesBlog.map((item, i) => {
                          return (
                            <div className="col-md-6 mb-1" key={i}>
                              <Link
                                className="text-decoration-none text-white"
                                to={`/news/${item.newsid}/${item.ntitle_slug}`}
                              >
                                {item.nphoto !== undefined && (
                                  <ImageDisplay
                                    url={`${item.nphoto}`}
                                    title={item.ntitle}
                                    category={item.ncat}
                                    height={100}
                                  />
                                )}
                                {item.ntitle}
                              </Link>
                            </div>
                          );
                        })}
                      </div>
                    </div>{" "}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-5 pt-2 order-1 order-md-2">
            <div className="row">
              {allNews.slice(0, 8).map((item, i) => {
                return (
                  <div className="col-md-6 p-1">
                    <Link
                      className="text-decoration-none text-dark"
                      to={`/news/${item.newsid}/${item.ntitle_slug}`}
                    >
                      <div className="row">
                        <div className="col-3">
                          {item.nphoto !== undefined &&
                          item.nphoto !== "" &&
                          item.nphoto !== null ? (
                            <ImageDisplay
                              url={`${item.nphoto}`}
                              title={item.ntitle}
                              category={item.ncat}
                              height={50}
                            />
                          ) : (
                            <div
                              style={{
                                width: "100%",
                                height: 50,
                                backgroundColor: "#333",
                              }}
                            ></div>
                          )}
                        </div>

                        <div className={"col-9 m-0 p-1"}>
                          {isMobile ? item.ntitle : getHeading(item.ntitle, 6)}
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>

            <div className="my-2">
              <CarouselDisplay data={allNews.slice(8, 14)} type="news" />
            </div>

            <div className="my-1 py-1">
              <LatestNewsMostRead />
            </div>
          </div>
          <div className="col-md-3 order-3 order-md-3">
            <RightColumn />
            <div>
              {showbiz.length > 0 && (
                <div style={{ backgroundColor: "#333333", color: "#ffffff" }}>
                  <img
                    src={news_xtra}
                    width={"100%"}
                    height={"100%"}
                    className="mb-2"
                  />
                  {showbiz.map((item, i) => {
                    return (
                      <Link
                        key={i}
                        className="text-decoration-none text-white"
                        to={`/news/${item.newsid}/${item.ntitle_slug}`}
                      >
                        <div className="row my-2 p-1">
                          <div className="col-4">
                            {item.nphoto !== undefined &&
                              item.nphoto !== "" &&
                              item.nphoto !== null && (
                                <ImageDisplay
                                  url={`${item.nphoto}`}
                                  title={item.ntitle}
                                  category={item.ncat}
                                  height={80}
                                />
                              )}
                          </div>
                          <div className="col-8">
                            <div>{item.ntitle}</div>
                            <span className="bg-danger p-1">
                              {item.nsubcat}
                            </span>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 p-2">
            {allNews.slice(14, 15).map((item, i) => {
              if (
                item.nphoto === "" ||
                item.nphoto === undefined ||
                item.nphoto === null
              ) {
                return (
                  <Link to={`/news/${item.newsid}/${item.ntitle_slug}`}>
                    <div
                      style={{
                        position: "relative",
                        bottom: 0,
                        height: isMobile ? 280 : 420,
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          width: "100%",
                          backgroundColor: "#000000",
                          opacity: 0.7,
                          bottom: 0,
                          height: isMobile ? 280 : 420,
                          padding: 20,
                          color: "#ffffff",
                        }}
                      >
                        <p
                          style={{ textShadow: "inherit" }}
                          className={item.nbrief ? "h3" : "h6"}
                        >
                          {item.ntitle}
                        </p>

                        <div className="px-1">
                          <span className="text-danger h4"> | </span>
                          {item.ncat.toUpperCase()}
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              } else {
                return (
                  <Link to={`/news/${item.newsid}/${item.ntitle_slug}`}>
                    <ImageDisplay
                      url={`${item.nphoto}`}
                      title={item.ntitle}
                      showText
                      largeimg
                      category={item.ncat}
                      height={isMobile ? 280 : 420}
                    />
                  </Link>
                );
              }
            })}
          </div>
          <div className="col-md-6">
            <div className="row">
              {allNews.slice(15, 19).map((item, i) => {
                return (
                  <div className="col-md-6 p-1">
                    <Link
                      className="text-decoration-none text-dark"
                      to={`/news/${item.newsid}/${item.ntitle_slug}`}
                    >
                      <ImageDisplay
                        url={`${item.nphoto}`}
                        title={item.ntitle}
                        showText
                        category={item.ncat}
                        height={210}
                      />
                      {isMobile && (
                        <div className="container border-bottom py-1">
                          <div className="h6">{item.ntitle}</div>
                          <div>
                            <span className="text-danger"> | </span>
                            <span className="small">
                              {item.ncat.toUpperCase()}
                            </span>
                          </div>
                        </div>
                      )}
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        {allNews.length > 5 && (
          <div className="h3 my-1 text-dark">
            <span style={{ color: "#ff0000" }} className="h2">
              |{" "}
            </span>{" "}
            <span className="h3">MORE NEWS</span>
          </div>
        )}

        <div className="row">
          {allNews.slice(19, 37).map((item, i) => {
            return (
              <div className="col-md-4 p-1">
                <Link
                  className="text-decoration-none text-dark"
                  to={`/news/${item.newsid}/${item.ntitle_slug}`}
                >
                  {!isMobile && (
                    <div className="row">
                      <div className="col-3">
                        <ImageDisplay
                          url={`${item.nphoto}`}
                          title={item.ntitle}
                          moreNews
                          category={item.ncat}
                          height={70}
                        />
                      </div>
                      <div className="col-9 mx-0 px-0">
                        <div className="h6">{item.ntitle}</div>
                      </div>
                    </div>
                  )}
                  {isMobile && (
                    <div className="container border-bottom py-1">
                      <div className="h6">{item.ntitle}</div>
                    </div>
                  )}
                </Link>
              </div>
            );
          })}
        </div>
      </div>

      <BannerTop num={2} from={0} to={2} />

      <div className="container">
        <div className="row my-1">
          <div className="col-md-12">
            <div className="row">
              {allSubCats.map((item, i) => {
                return (
                  <CategoryNewsDisplay
                    key={i}
                    cat={item.nsubcat}
                    id={item.nsubcatid}
                    cols={12}
                  />
                );
              })}
            </div>
          </div>
          {/* <div className="col-md-3">
            <BannerSide num={1} />

            {featured.length > 0 && (
              <div className="container my-2 py-2">
                {" "}
                <h3>Featured Stories</h3>
                {featured.map((item, i) => {
                  return (
                    <Link
                      className="text-decoration-none text-dark"
                      to={`/news/${
                        item.newscatid
                      }/${item.ncat.toLowerCase()}/${item.newsid}/${
                        item.ntitle_slug
                      }`}
                    >
                      <div
                        style={{ backgroundColor: "#cccccc" }}
                        className="row my-3 py-2 border-bottom"
                      >
                        <div className="col-md-12 mx-0 px-0 col-3">
                          <ImageDisplay
                            url={`${item.nphoto}`}
                            title={item.ntitle}
                            height={isMobile ? 80 : 200}
                          />
                        </div>
                        <div className="col-md-12 col-9">
                          <div className="h6 my-1 py-1">{item.ntitle}</div>
                          <div className="px-1">
                            <span className="text-danger h4"> | </span>
                            {item.ncat.toUpperCase()}
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            )}
          </div> */}
        </div>
      </div>

      <BannerTop num={2} from={2} to={4} />

      <Footer />
    </div>
  );
};

export default Home;
