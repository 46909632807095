import React, { useState, useEffect, useContext } from "react";
import { Header, Footer } from "./";
import { Pagination, NavigationBar } from "../components";
import moment from "moment";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { DashboardMenu } from "../components";
import { useDocumentTitle } from "../utils";
import { SiteContext } from "../context";
import ReactModal from "react-modal";
import Seo from "./Seo";

const PageDetail = () => {
  let { id } = useParams();

  const { apiUrl, handleCurrentPage, pages, siteconfig } =
    useContext(SiteContext);

  const [sitepage, setSitepage] = useState({});
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    getPage();
    handleCurrentPage(id === "about" ? "about" : "");
    window.scrollTo(0, 0);
  }, [id]);

  const getPage = () => {
    let filtered = pages.filter(
      (item) => item.cpagelinkname.toLowerCase() === id
    );
    if (filtered.length > 0) {
      setSitepage(filtered[0]);
    }
  };

  return (
    <div>
      <Seo
        title={
          id.charAt(0).toUpperCase() +
          id.slice(1).replace(/-/gi, " ") +
          " | " +
          siteconfig.shopname
        }
        description={
          id.charAt(0).toUpperCase() + id.slice(1).replace(/-/gi, " ")
        }
        url={`${apiUrl}/page/${id}`}
        img={`${apiUrl}/${siteconfig.shoplogo}`}
      />
      <Header />
      <div>
        <div className="container">
          <NavigationBar
            section="Page"
            page={sitepage.hasOwnProperty("cpageid") && sitepage.cpagename}
            goback={false}
          />

          <h3>{sitepage.hasOwnProperty("cpageid") && sitepage.cpagename}</h3>

          <div className="my-2 d-flex justify-content-between">
            <div className="align-items-center">
              {msg !== "" ? (
                <div className="p-2">
                  <span className="p-2 border border-info text-dark">
                    {msg}
                  </span>
                </div>
              ) : null}
              {errorMsg !== "" ? (
                <div className="p-2">
                  <span className="p-2 border border-info text-dark">
                    {errorMsg}
                  </span>
                </div>
              ) : null}
            </div>
          </div>

          {sitepage.hasOwnProperty("cpageid") && (
            <div className="row my-2 py-2">
              <div
                className="col-md-8"
                dangerouslySetInnerHTML={{ __html: sitepage.cpagecontent }}
              />
              {sitepage.cpagephoto !== undefined &&
                sitepage.cpagephoto !== "" &&
                sitepage.cpagephoto !== null && (
                  <div className="col-md-4 col-12">
                    <img
                      className="img-fluid"
                      src={`${apiUrl}/images/documents/${sitepage.cpagephoto}`}
                      height={150}
                      width="100%"
                      alt={sitepage.cpagename}
                    />
                  </div>
                )}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PageDetail;
