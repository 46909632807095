import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { SiteContext } from "../context";
import { ImageDisplay } from "./";

const CategoryNewsDisplay = ({ cat, id, cols }) => {
  const { apiUrl, siteconfig, allNews, isMobile } = useContext(SiteContext);

  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    let filteredNews = allNews
      .filter((item) => parseInt(item.newssubcatid) === parseInt(id))
      .slice(0, 3);
    setData(filteredNews);
  };

  const doSanitize = (item) => {
    if (item === "" || item === undefined) {
      return item;
    }
    return item
      .replace(/<p>/g, "")
      .replace(/<\/p>/g, " ")
      .replace("&nbsp;", "")
      .replace(/&rsquo;/g, "'");
  };

  if (data.length < 2) {
    return null;
  }

  return (
    <div className="col-md-4 my-1 py-1 mx-0 container">
      <div className="h3 my-1 text-dark">
        <span style={{ color: "#ff0000" }} className="h2">
          |{" "}
        </span>{" "}
        <span className="h4">{cat.toUpperCase()}</span>
      </div>
      <div className="row">
        {data.map((item, i) => {
          return (
            <div className={`col-md-${cols} my-1`}>
              <Link
                className="text-decoration-none text-dark"
                to={`/news/${item.newsid}/${item.ntitle_slug}`}
              >
                {item.nphoto !== undefined ? (
                  <div className="row">
                    <div className="col-3">
                      {!isMobile &&
                      item.nphoto !== undefined &&
                      item.nphoto !== "" &&
                      item.nphoto !== null ? (
                        <ImageDisplay
                          url={`${item.nphoto}`}
                          title={item.ntitle}
                          category={item.ncat}
                          height={70}
                        />
                      ) : (
                        <div
                          style={{
                            width: "100%",
                            height: 70,
                            backgroundColor: "#333",
                          }}
                        ></div>
                      )}
                    </div>

                    <div className={"col-9 mx-0 px-0"}>
                      <div className={isMobile ? "py-2 border-bottom" : "py-2"}>
                        {item.ntitle}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className={"col-12"}>
                      <div className={isMobile ? "py-2 border-bottom" : "py-1"}>
                        {item.ntitle}
                      </div>
                    </div>
                  </div>
                )}
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CategoryNewsDisplay;
