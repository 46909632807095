import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { shell_ad } from "../assets";
import { SiteContext } from "../context";

const ShellAd = () => {
  return (
    <div className="rounded shadow bg-white my-2 py-2">
      <div className="text-muted small text-end">ADVERTISEMENT</div>

      <div className="px-1 my-2 text-center">
        <a href="https://www.shell.com.ng/" target='_blank' className="text-decoration-none"><img
          style={{ objectFit: "cover" }}
          className="img-fluid"
          src={shell_ad}
          height={'auto'}
          width={`100%`}
        /></a>
      </div>
    </div>
  );
};

export default ShellAd;
